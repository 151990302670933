@import "colors";

.h-100 {
  height: 100%;
}
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
  width: 100%;
  justify-content: center;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  min-height: 100vh;
}

.mt-10 {
  margin-top: 10px;
}

.font-regular {
  font-size: 14px;
}

.font-x-small {
  font-size: 10px;
}

.font-small {
  font-size: 12px;
}

.font-medium {
  font-size: 16px;
}

.font-large {
  font-size: 20px;
}

.font-x-large {
  font-size: 24px;
}

.font-x-large-medium {
  font-size: 26px;
}

.font-xx-large {
  font-size: 32px;
}

.montserrat-semi-bold {
  font-family: 'Montserrat Semibold';
}

.montserrat-regular {
  font-family: 'Montserrat Regular';
}

.montserrat-medium {
  font-family: 'Montserrat Medium';
}

.montserrat-light {
  font-family: 'Montserrat Light';
}

.feedback-error {
  color: $softRed;
  font-family: 'Montserrat Medium';
}

.bad-circle {
  height: 12px;
  width: 12px;
  background: $softRed;
  border-radius: 6px;
}

.medium-circle {
  height: 12px;
  width: 12px;
  background: $orangeYellow;
  border-radius: 6px;
}

.good-circle {
  height: 12px;
  width: 12px;
  background: $deepSkyBlue;
  border-radius: 6px;
}

.excellent-circle {
  height: 12px;
  width: 12px;
  background: $greenSerum;
  border-radius: 6px;
}

.me-auto{
  margin-right: auto;
}

.ms-auto{
  margin-left: auto;
}

.my-auto{
  margin-top: auto;
}

.mb-auto{
  margin-bottom: auto;
}

.mx-auto{
  margin-top: auto;
  margin-bottom: auto;
}

.my-auto{
  margin-left: auto;
  margin-right: auto;
}

.loader {
  height: 400px;
}

.loader-large {
  height: 694px;
}

.Empty-style{
  padding: 60px;
  justify-content: center;
  align-items: center;
}

.button-table-row{
  text-decoration: none;
  border: none;
  padding: 0;
  background: none;
  &:hover{
    text-decoration: underline;
    text-decoration-color: $darkBlue;
    color: $darkBlue;
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
}