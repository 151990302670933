@import "src/styles/colors";
.copyright-container {
  padding-top: 10.59375rem;
  .text-copyright {
    color: $tuna;
  }
  .vertical-bar {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    color: $darkBlue;
  }
  .text-links {
    color: $darkBlue;
  }
}

@media (max-width: 1199px) {
  .login-card-container {
    .left-container {
      padding-top: 13rem;
      padding-bottom: 3rem;
      .copyright-container {
        .text-copyright {
          text-align: center !important;
          width: 100%;
          justify-content: center;
        }
      }
    }
    .right-container {
      .text-container {
        padding: 0 1rem;
      }
    }
  }
}