@import "./src/styles/colors";

.sidebar-container {
  height: calc(100vh - 5.75rem);
  .ant-menu-item {
    height: 50px !important;
    padding: 0 46px !important;
  }
  .ant-menu.ant-menu-dark {
    background: $darkBlue;
  }

  .ant-menu-item-disabled {
    cursor: default;
  }

    .ant-menu-item-icon {
      margin-top: 12px !important;
      width: 24px !important;
      height: 24px !important;
    }

  .ant-menu-title-content {
    color: $white;
    font-family: "Montserrat Regular";
    font-size: 16px;
    opacity: 0.5 !important;
    top: -7.5px !important;
    display: inline-block;
    position: relative;
    line-height: normal;
  }

  .ant-menu-item-icon + span {
    margin-left: 13.5px !important;
  }

  .ant-menu-item-selected {
    background: $ghostWhite !important;
    border-right: 8px solid $deepSkyBlue !important;

    .ant-menu-title-content {
      color: $darkBlue !important;
      font-size: 16px;
      opacity: 1 !important;
    }

    .ant-menu-item-icon {
      g {
        opacity: 1 !important;
        path {
          stroke: $darkBlue !important;
        }
        line {
          stroke: $darkBlue !important;
        }
      }
    }
  }

  .ant-menu-inline-collapsed {
    width: 82px !important;
    .ant-menu-item {
      padding-left: 19px !important;
    }
    .ant-menu-title-content {
      margin-left: 39px !important;
    }
  }

  .collapsed-icon-container {
    opacity: 1 !important;

    .ant-menu-title-content {
      opacity: 1 !important;
      float: right;
      top: 0;
      left: 255px;
      .expanded-icon {
        position: fixed;
        left: 257px;
        top: 105px;
        transition: all 0.2s;
        cursor: pointer;
        z-index: 99;
      }
      .collapsed-icon {
        transform: rotate(180deg);
        position: fixed;
        left: 62px;
        top: 105px;
        cursor: pointer;
        transition: all 0.2s;
      }
    }

  }
}

.ant-layout-sider {
  background: $darkBlue !important;
}

@media (max-width: 992px) {
  .sidebar-container {
    .ant-layout-sider-zero-width-trigger {
      display: none;
    }
    .collapsed-icon {
      display: none;
    }
    .expanded-icon {
      display: none;
    }
  }
}
