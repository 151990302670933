@import 'colors';
@import 'montserrat';

html,
body {
  margin:0px;
  height:100%;
}

body {
  font-family: 'Montserrat Regular', Helvetica, sans-serif;
  color: $trout;
  font-weight: normal;
}
