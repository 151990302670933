@import "./src/styles/colors";
.notification {
  border-radius: 4px;
  box-shadow: 0 3px 24px #00000014;
  .ant-notification-notice-message {
    color: $vulcan;
    font-family: "Montserrat Medium" !important;
    font-size: 14px !important;
  }
  .ant-notification-notice-description{
    color: $vulcan;
    font-family: "Montserrat Regular" !important;
    font-size: 12px !important;
  }
}

.notification-only-title {
  .ant-notification-notice {
    padding: 32px 24px;
  }
}

.notification-error {
  border-left: 6px solid $softRed;
}

.notification-success {
  border-left: 6px solid $strongGreen;
}

.notification-info {
  border-left: 6px solid $darkBlue;
}

.notification-warning {
  border-left: 6px solid $vividYellow;
}
