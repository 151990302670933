@import "./src/styles/colors";
@import "./src/styles/variables";

.no-access-container {
  background: $white;
  padding: 60px 22px 60px 22px;

  .ant-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 40px;

    svg{
      margin: 0 auto;
    }
  }
  .red-title{
    font-size: 3.5rem;
    color: $softRed;
    font-weight: bold;
    line-height: 3.5rem;
    margin: 0;
  }
  .subtitle{
    font-weight: bold;
    margin-bottom: 49px;
  }
  .copyright-container{
    width: fit-content;
    margin-left: auto;
  }
}

@media (max-width: $md) {
  .no-access-container{
    .ant-col{
      margin: 0;
    }
    .image-container{
      svg{
        width: 100%;
      }
    }
  }
}