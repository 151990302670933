@import "./src/styles/colors";

.custom-modal-container {
  width: 408px !important;
  border-radius: 4px !important;
  color: $vulcan;
  .info-icon {
    margin-right: 20px;
  }
  .custom-modal-title {
    line-height: 24px !important;
    margin-bottom: 0 ;
  }
  .custom-modal-subtitle {
    margin-bottom: 0;
  }
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-footer {
    border-top: 0;
  }
}
.custom-modal-container-true {
  width: 700px !important;
}
.custom-modal-with-children {
  .ant-modal-header {
    padding: 24px 40px 24px 36px;
  }
  .ant-modal-body {
    padding: 24px 40px 24px 40px;
  }
  .ant-modal-footer {
    padding: 0 40px 40px 78px;
  }
}

.custom-modal-without-children {
  .ant-modal-header {
    padding: 24px 40px 24px 24px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 20px 40px 40px 78px;
  }
}

.error {
  .ant-modal-header {
    border-left: 6px solid $softRed;
  }
}

.success {
  .ant-modal-header {
    border-left: 6px solid $greenSerum;
  }
}

.info {
  .ant-modal-header {
    border-left: 6px solid $darkBlue;
  }
}

.warning {
  .ant-modal-header {
    border-left: 6px solid $vividYellow;
  }
}
