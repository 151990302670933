@import "./src/styles/colors";
@import "./src/styles/variables";

.user-info-card-container.comment-container {
  max-width: 100%;
  padding-right: 4rem;
  .user-info-card-avatar {
    //margin-right: 38px;
    width: 98px;
    .avatar {
      height: 60px;
      width: 60px;
      border-radius: 60px;
      -moz-border-radius:50%;
      -webkit-border-radius:50%;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }
    .avatar-link {
      cursor: pointer;
    }
    .avatar:hover {
      box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
    }
  }
  .user-info-card-content {
    width: calc(100% - 98px);
    .user-info-card-name {
      padding-top: 7.5px;
      color: $veryDarkGrayishBlue;
      line-height: 24px;
    }

    .user-info-card-link:hover {
      cursor: pointer;
      color: $darkBlue;
      text-decoration: underline;
    }

    .user-info-card-date {
      color: $darkGrayishBlue;
    }
    .user-info-card-status {
      margin: 10px 0;
    }
    .user-info-card-country {
      color: $veryDarkGrayishBlue;
      line-height: 14px;
      padding-bottom: 10px;
    }
    .Inactive-tag {
      background: $softRed;
      color: $white;
      border: 1px solid $softRed;
    }

    .Active-tag {
      background: $greenSerum;
      color: $white;
      border: 1px solid $greenSerum;
    }
    .user-info-card-rate-container {
      margin-top: 10px;
      .user-info-card-rate {
        margin-right: 30px;
      }
      .user-info-card-icon {
        margin-top: 5px;
        border-radius: 4px;
      }
      .user-info-card-icon:hover {
        background: $darkBlue;

        rect {
          stroke: $darkBlue;
        }
        path {
          stroke: $white;
        }
      }
    }
  }
  .divider-container {
    text-align: center;

    .ant-divider {
      font-size: 48px;
    }

    .ant-divider-vertical {
      width: 0.5px;
      border-left: 0.5px solid $darkGrayishBlue;
    }
  }
  .user-info-card-content-detail {
    width: calc(50% - 158px) !important;
  }
  .user-info-card-projects {
    .title {
      color: $veryDarkGrayishBlue;
      padding-top: 20.5px;
      padding-bottom: 20px;
      .size {
        color: $deepSkyBlue;
        font-size: 13px;
        padding-left: 10px;
      }
    }
    .ant-progress-circle {
      .ant-progress-circle-trail {
        stroke-width: 12.5;
        stroke: #cad4de;
      }
      .ant-progress-circle-path {
        stroke-width: 12.5;
        stroke-linecap: square;
        stroke: $deepSkyBlue;
      }
    }
  }
}

.check-feedback{
  color: $strongGreen !important;
}

.close-feedback{
  color: $softRed !important;
}

@media (max-width: 992px) {
  .user-info-card-container {
    .user-info-card-content-detail {
      width: calc(100% - 158px) !important;
    }
  }
}
@media (max-width: 767px) {
  .user-info-card-container {
    .divider-container {
      display: none;
    }
  }
}

@media (max-width: $md) {
  .user-info-card-container {
    padding-right: 0;
  }
}

