$veryDarkGrayishBlue: #434C51;
$darkGrayishBlue: #8695A2;
$darkBlue: #114AB1;
$trout: #4D4F5C;
$white: #FFFFFF;
$vulcan: #35383F;
$tuna: #4b5159;
$ghostWhite: #F9FAFF;
$softRed: #F04E4E;
$vividYellow: #F6D61C;
$strongGreen: #87BE2B;
$grayishBlue: #8d92a4;
$deepSkyBlue: #03C5F8;
$gulGray: #A4B2BE;
$linkWater: #E8EBF8;
$ghost: #F3F5FF;
$greenSerum: #98E11F;
$blue: #0A0A53;
$nepal: #CAD4DE;
$orangeYellow: #FFCC00;
$blueFacebook: #4267B2;
$blueLinkedin: #0e76a8;
$blueTwitter: #1DA1F2;
$redInstagram: #e95950;