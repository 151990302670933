@import "colors";
@import "montserrat";

::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

::-webkit-scrollbar-thumb {
  background: $darkBlue;
  border-radius: 15px;
}


.ant-btn-primary {
  background: $darkBlue;
  font-family: 'Montserrat Regular', serif;
  font-size: 12px;
  color: $white;
  border-radius: 10px;
  height: 40px;
  width: 100%;
}

.ant-btn-secondary {
  background: $gulGray;
  color: $white;
  font-size: 12px;
  font-family: 'Montserrat Regular', serif;
  border-color: $gulGray;
  border-radius: 10px;
  height: 40px;
  width: 100%;
}

.ant-input-affix-wrapper {
  border: 1px solid $darkGrayishBlue;
  height: 40px;
  color: $darkGrayishBlue ;
  font-family: 'Montserrat Regular', serif;
  font-size: 14px;
  width: 100%;
  .ant-input-prefix {
    color: $darkGrayishBlue;
  }
}

.ant-form-item {
  margin-bottom: 28px;
}

.ant-checkbox-inner {
  border-color: $darkGrayishBlue !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $darkBlue;
  border-color: $darkBlue !important;
  border-radius: 2px;
}
.ant-checkbox + span {
  padding-left: 1rem;
}
.ant-form-item-control-input {
  min-height: 0;
}
.ant-tag {
  color: $veryDarkGrayishBlue;
  background: $white;
  border-radius: 11.5px;
  border: 1px solid $veryDarkGrayishBlue;
  font-size: 14px;
  height: 24px;
  font-family: "Montserrat Regular";
  padding: 0 20px;
}

.ant-rate-star-second {
  color: #CAD4DE;
}
.ant-rate {
  color: #F6D61C;
  font-size: 24px;
}
.ant-tag-close-icon {
  margin-left: 10px;
  font-size: 14px;
}

.ant-picker {
  width: 100%;
}

.ant-tooltip-inner {
  background: $white;
  color: $veryDarkGrayishBlue;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 8px;
}
.ant-tooltip-arrow-content {
  background: $white;
}

.children-container {
  padding-right: 0 !important;
  overflow-y: auto !important;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
  color: $darkBlue;
  font-size: 13px;
}