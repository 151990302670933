@import "./src/styles/colors";
@import "./src/styles/variables";

.header {
  width: 100%;
  min-height: 5.75rem;
  background: $white;
  align-items: center;
  position: relative;
  z-index: 2;
  .logo-container {
    padding-left: 2.375rem;
  }

  .header-user-information {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 65px;
    z-index: 100;

    .notification {
      .ant-badge-count {
        background: $darkBlue;
        font-family: "Montserrat Medium";
        font-size: 12px;
        color: $white;
      }
      .cursor-pointer{
        cursor: pointer;
      }
    }
    .user-info-container {
      padding-left: 1.875rem;
      >div{
        cursor: pointer;
      }
      .user-name {
        padding: 0 0.625rem;
        font-family: "Montserrat Regular";
        font-size: 16px;
        color: $veryDarkGrayishBlue;
      }

      .arrow-icon {
        fill: $tuna;
      }
    }
  }
  .ant-dropdown-menu {
    .sub-menu {
      background: black;
      .ant-dropdown-menu-title-content {
        color: red !important;
      }
    }
  }
  .menu-icon {
    display: none;
  }
}

.ant-layout {
  background: $ghostWhite !important;
}

.children-container {
  height: calc(100vh - 5.75rem);
  overflow: auto;
  padding-right: 5px;
  background: $white;
  overflow-x: hidden;
  .main-content{
    overflow: hidden;
  }
}

.children-container:hover,
.children-container:active,
.children-container:focus {
  overflow-y: scroll;
  padding-right: 0;
}

.menu-container {
  padding: 0.875rem 0 !important;
  border-radius: 8px !important;
  margin-right: 4.16666667% !important;
  width: 200px;
  .ant-dropdown-menu-item {
    height: 40px;
    padding-left: 21px ;
    margin: 8px 0;
  }

  .ant-dropdown-menu-item:hover{
    background: $white;
  }


  .ant-dropdown-menu-item-icon {
    color: $grayishBlue;
    font-size: 16px;
    margin-right: 11px;
  }

  .ant-dropdown-menu-title-content {
    color: $grayishBlue;
    font-size: 16px;
  }

  &:not(.notifications){
    .ant-dropdown-menu-item-active {
      border-left: 4px solid $darkBlue !important;
      .ant-dropdown-menu-item-icon {
        color: $darkBlue;
      }
      .ant-dropdown-menu-title-content {
        color: $darkBlue;
      }
    }
  }
  .ant-divider-horizontal {
    margin: 17px 0;
  }

}
@media (max-width: $lg) {
  .header {
    .logo-container {
      width: 40%;
      padding-left: 2.475rem;
    }
    .menu-icon {
      font-size: 24px;
      display: block;
      width: 18%;
      padding-left: 3%;
    }
    .header-user-information {
      width: 42%;
      margin: 0;
      justify-content: end;
      padding-right: 3%;
    }
  }
}
@media (max-width: $md) {
  .header {
    .logo-container {
      padding-left: 0;
    }
  }
}

@media (max-width: 720px) {
  .header {
    .logo-container {
      width: 40%;
      padding-left: 5%;
    }
    .avatar-icon {
      padding-right: 0.5rem;
    }
    .user-name {
      display: none;
    }
  }
  .ant-dropdown-arrow {
    right: 40px !important;
  }
}

@media (max-width: 520px) {
  .header {
    .logo-container {
      width: 55%;
      padding-left: 7.5%;
    }
    .menu-icon {
      width: 15%;
    }
    .header-user-information {
      width: 30%;
    }
  }
}

@media (max-width: $xs) {
  .header {
    .logo-container {
      width: 55%;
      padding-left: 10%;
      .logo {
        height: 50px;
      }
    }
    .menu-icon {
      width: 5%;

    }
    .header-user-information {
      width: 40%;
    }
  }
}


@media (max-width: $xxs) {
  .header {
    .logo-container {
      width: 55%;
      padding-left: 1%;
      .logo {
        height: 50px;
      }
    }
    .menu-icon {
      width: 5%;

    }
    .header-user-information {
      width: 40%;
    }
  }
}