@import "./src/styles/colors";
@import "./src/styles/variables";

.dashboard-info-container {
  padding-right: 60px;
  padding-left: 60px;
  border-bottom: 0.5px solid $darkGrayishBlue;
  background: $ghostWhite;
  height: auto;
  .message {
    color: $veryDarkGrayishBlue;
    line-height: 16px;
  }
  p {
    margin-bottom: 7px;
  }
  .user-message {
    color: $darkBlue;
    line-height: 32px;
  }
  .user-info-container {
    padding: 12px 0 12px 0;
  }
  .white-background{
    background: $white;
  }
  .datetime-container {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 12px 0;
    .datetime-content {
      align-content: center;
      justify-content: center;
      text-align: center;
      .main-content{
        color: $tuna;
      }
      .label {
        color: $veryDarkGrayishBlue;
      }
    }
  }
  .hours-worked-container {
    padding-left: 20px;
  }

  .days-worked-container {

  }
  .divider-container {
    text-align: center;
    .ant-divider {
      font-size: 48px;
    }
    .ant-divider-vertical {
      width: 0.5px;
      border-left: 0.5px solid $darkGrayishBlue;
    }
  }

  .ant-progress-inner {
    background: $white;
  }
}

@media (max-width: $xxl) {
  .dashboard-info-container {
    .white-background {
      background: none;
    }
  }
}

@media (max-width: $lg) {
  .dashboard-info-container {
    padding: 0 3%;
    height: auto;
    .datetime-container {
      align-items: center;
      align-content: center;
      display: flex;
      justify-content: center;
      border-bottom: none;
      padding: 0 0 12px 0;
    }
    .date-container {
      border-bottom: none;
    }
    .hours-worked-container {
      padding-left: 0;
      background: $ghostWhite;
      width: 100%;
    }
    .divider-container {
      text-align: center;
      justify-content: center;
    }
    .days-worked-container {
      width: 100%;
    }
    .user-info-container {
      padding: 20px 0 12px 0;
    }
  }
}
