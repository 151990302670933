@import "./src/styles/colors";

.feedback-modal{
  .ant-modal-body{
    padding-top: 0 !important;
  }
}
.notifications {
  width: fit-content;
  max-height: 276px;
  padding: 0 !important;
  border-radius: 8px !important;
  margin-right: 4.16666667% !important;
  overflow-y: scroll;

  li.ant-dropdown-menu-item{
    height: initial;
    padding: 0;
    margin: 0;
    .notification{
      min-width: 300px;
      margin: 0;
      .ant-notification-notice-close{
        font-size: 12px;
      }
      .ant-notification-notice-message{
        padding-right: 48px;
      }
    }
  }
}

.empty-notifications{
  margin: 10px 10px 10px 20px !important;
}

.comment-list{
  display: flex;
  justify-content: space-between;
  .actions{
    display: flex;
    justify-content: space-around;
    line-height: 32px;
    font-size: 32px;
    span{
      margin: auto 10px;
    }
  }
}

.issue-description{
  padding-bottom: 2rem;
  border-bottom: 1px solid $trout;
  .description{
    margin-bottom: 24px;
  }
}

.issue-comments{
  padding: 2rem 0;
}